<template>
  <b-card-code title="Ajouter un employé">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de employé</span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Nom et prénom de l'employé"
              label-for="employee"
            >
              <validation-provider
                #default="{ errors }"
                name="nom et prénom de l'employé"
                rules="required"
              >
                <b-form-input
                  v-model="workCertificate.employee"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nom et prénom de l'employé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="cin"
              label-for="cin"
            >
              <validation-provider
                #default="{ errors }"
                name="cin"
                rules="required"
              >
                <b-form-input
                  id="cin"
                  v-model="workCertificate.cin"
                  placeholder="cin"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="emploi"
            >
              <validation-provider
                #default="{ errors }"
                name="emploi"
                rules="required"
              >
                <v-select
                  v-model="workCertificate.job"
                  :options="jobs"
                  :clearable="false"
                  :reduce="job => job.id"
                  label="title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="date debut"
              rules="required"
            >
              <b-form-group
                label="date début"
                label-for="start_date"
                rules="required"
              >
                <flat-pickr
                  v-model="workCertificate.start_date"
                  class="form-control invoice-edit-input"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="date fin"
              rules="required"
            >
              <b-form-group
                label="date fin"
                label-for="end_date"
                rules="required"
              >
                <flat-pickr
                  v-model="workCertificate.end_date"
                  class="form-control invoice-edit-input"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="toujours employé ?"
              label-for="still_employed"
            >
              <b-form-checkbox
                v-model="workCertificate.still_employed"
                class="mr-0 mt-50"
                switch
                inline
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addWorkCertificate"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      workCertificate: {
        employee: '',
        cin: '',
        job: '',
        start_date: '',
        end_date: '',
        still_employed: false,
      },
      required,
    }
  },
  computed: {
    ...mapGetters('job', ['jobs']),
  },
  created() {
    this.getJobs()
  },
  methods: {
    ...mapActions('job', ['getJobs']),
    async addWorkCertificate() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axiosIns.post('/work-certificates/create/', this.workCertificate)
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Attestation ajouté avec succès')
            }, 1000)
            this.$router.push({ name: 'work-certificates' })
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
